export const NavigationItems = [
  {
    label: "About",
    link: "/about",
  },
  {
    label: "The Creatives",
    link: "/the-creatives",
  },
  {
    label: "Productions",
    link: "/productions/0",
  },
  // {
  //   label: "Press",
  //   link: "/press",
  // },
  {
    label: "Reach Out",
    link: "/reach-out",
  },
];
